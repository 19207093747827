export default function convertBase64ToBlob(document) {
  const base64Content = document.replace(/^data:application\/pdf;base64,/, "");
  const byteArray = new Uint8Array(
    atob(base64Content)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const pdfUrl = URL.createObjectURL(blob);
  return pdfUrl;
}
