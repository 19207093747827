<template>
  <div class="document-container" :class="[!previewMode ? 'document-image' : '', isPaymentsDocument ? 'payment-viewer' : '']" id="viewer">
    <div class="back-icon" v-if="!isPaymentsDocument" @click="hidePdfViewer()">
      <i class="ion ion-close-circle-outline"></i>
    </div>
    <div class="paginator col-12 d-flex justify-content-center mb-3" v-if="!previewMode">
      <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
        <b-input-group>
          <b-input-group-prepend>
            <b-button @click="previous()" :class="previousLogic">
              <i class="ion ion-chevron-back-outline"></i>
            </b-button>
          </b-input-group-prepend>
          <input type="number" v-model="page" :max="totalPage" />
          <b-input-group-append>
            <b-input-group-text variant="outline-light">/ {{ totalPage }}</b-input-group-text>
            <b-button @click="next()" :class="nextLogic">
              <i class="ion ion-chevron-forward-outline"></i>
            </b-button>
          </b-input-group-append>
          <b-input-group-append>
            <button class="button-download" @click="downloadDocument(pdfDocument)">
              <i class="ion ion-cloud-download-outline"></i>Download
            </button>
          </b-input-group-append>
          <b-input-group-append v-if="!tokenInfo.is_branded && !isStatement">
            <button class="button-share" @click="shareDocument(pdfDocument)">
              <i class="ion ion-mail-outline"></i>Share
            </button>
          </b-input-group-append>
        </b-input-group>
        <!-- Print button -->

        <!-- <b-input-group class="mx-1">
          <b-button @click="$refs['pdf'].print()" class="ml-3" variant="outline-light"
            ><b-icon icon="printer"></b-icon
          ></b-button>
        </b-input-group> -->
      </b-button-toolbar>
    </div>
    <div class="buttons-container" v-if="previewMode">
      <button class="button-open" @click="openDocument()" v-if="!isStatement">
        <i class="ion ion-expand-outline"></i>
      </button>
      <button class="button--download" @click="downloadDocument(pdfDocument)">
        <i class="ion ion-cloud-download-outline"></i>
      </button>
      <button
        v-if="!tokenInfo.is_branded && !isStatement"
        class="button-share"
        @click="shareDocument(pdfDocument)"
      >
        <i class="ion ion-mail-outline"></i>
      </button>
    </div>
    <pdf
      class="mb-5 pdf-document"
      ref="pdf"
      :src="pdfDocument"
      @num-pages="totalPage = $event"
      @page-loaded="currentPage = $event"
      :page="page"
    ></pdf>
  </div>
</template>

<script>
import downloadDocument from "@/utils/DownloadDocuments";
import pdf from "vue-pdf";
import convertBase64ToBlob from "@/utils/ConvertBase64ToBlob";

export default {
  name: "PdfViewer",
  props: {
    invoiceDocument: Array,
    isStatement: Boolean,
    selectedDocument: String,
    previewMode: Boolean,
    currentType: String,
    chosenLoad: Object,
    isPaymentsDocument: Boolean,
    paymentDocument: Object
  },
  components: {
    pdf,
  },
  data() {
    return {
      page: 1,
      numPage: 0,
      totalPage: 0,
      currentPage: 0,
      disable: false,
      tokenInfo: null,
      pdfDocument: null,
    };
  },
  watch: {
    selectedDocument: {
      immediate: true,
      handler() {
        this.page = 1;
        this.pdfDocument = this.selectedDocument;
      },
    },
    invoiceDocument: {
      immediate: true,
      handler() {
        if (this.invoiceDocument && this.selectedDocument) {
          this.pdfDocument = convertBase64ToBlob(this.selectedDocument);
        }
      },
    },
  },
  computed: {
    previousLogic() {
      if (this.page === 1) {
        return "disabled";
      }
      return "enebled";
    },
    nextLogic() {
      if (this.page === this.totalPage) {
        return "disabled";
      }
      return "enebled";
    },
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
  },
  methods: {
    hidePdfViewer() {
      this.$emit("pdfViewerHidden", this.currentType);
      this.$emit("enableScroll");
    },
    openDocument() {
      this.$emit("documentOpened", this.selectedDocument);
      this.$emit("disableScroll");
    },
    shareDocument(selectedDocument) {
      if (this.isPaymentsDocument) {
        this.$emit("shareDocument", this.paymentDocument);
        return;
      }
      if (!this.previewMode) {
        this.$emit("pdfViewerHidden", this.currentType);
      }
      this.$emit("shareDocument", selectedDocument);
    },
    downloadDocument(url) {
      if (this.invoiceDocument) {
        downloadDocument(url, this.invoiceDocument[0].name);
        return;
      }
      downloadDocument(url, `${this.currentType.replace("/", "").replace("/", "")}`);
    },
    previous() {
      this.page -= 1;
    },
    next() {
      this.page += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-document {
  width: 100%;
}

.back-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  color: white;
  cursor: pointer;
  i {
    font-size: 30px;
    font-weight: bold;
  }
}

.disabled {
  pointer-events: none;
}

.paginator {
  text-align: center;
  .input-group {
    justify-content: center;
  }
}
input {
  width: 45px;
  outline: none;
  border: none;
  text-align: center;
  pointer-events: none;
  color: $color-primary-company;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
button {
  background: $color-white;
  color: $color-primary-company;
  border: none;
}
button:hover {
  background: $color-primary-company;
  color: $color-white;
}
.buttons-container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  z-index: 99;
  margin: 10px;
  button {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    padding-right: 13px;
    padding-left: 13px;
    border-radius: 7px;
    margin-left: 5px;
    background: $color-white;
    color: $color-primary-company;
    border: 1px solid $color-border-container;
    &:hover {
      color: $color-white;
      background: $color-primary-company;
      transition: 0.2s all;
      border: $color-white;
    }
  }
}

.button-download {
  display: flex;
  align-items: center;
  color: $color-border-container;
  @include font-small-button-text;
  padding-right: 10px;
  font-weight: 400;
  i {
    margin-right: 5px;
    font-size: 15px;
  }
}
.button-share {
  display: flex;
  align-items: center;
  border-left: 1px solid rgb(207, 207, 207);
  color: $color-border-container;
  @include font-small-button-text;
  font-weight: 400;
  padding-right: 10px;
  i {
    margin-right: 5px;
    font-size: 15px;
  }
}
.document-image {
  width: 80%;
  height: 100vh;
  display: block;
  align-items: center;
  margin: 2.5% auto;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.document-container {
  position: relative;
}

.payment-viewer {
  width: 100%;
}
</style>
